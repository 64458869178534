define('ember-model-validator/mixins/model-validator', ['exports', 'ember', 'ember-model-validator/messages/en', 'ember-model-validator/postal-codes-regex'], function (exports, _ember, _emberModelValidatorMessagesEn, _emberModelValidatorPostalCodesRegex) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    validationErrors: {},
    isValidNow: true,
    addErrors: true,

    validate: function validate() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var store = this.get('store'),
          errors = null,
          validations = this.get('validations');

      // Clean all the current errors
      this.get('errors').clear();
      this.set('validationErrors', {});
      this.set('isValidNow', true);
      errors = this.get('validationErrors');

      // Validate but not set errors
      if (options.hasOwnProperty('addErrors')) {
        this.set('addErrors', options['addErrors']);
      } else {
        this.set('addErrors', true);
      }

      // Call validators defined on each property
      for (var property in validations) {
        for (var validation in validations[property]) {
          if (this._exceptOrOnly(property, options)) {
            var validationName = _ember['default'].String.capitalize(validation);
            if (_ember['default'].get(validations[property], validation + '.allowBlank') && _ember['default'].isBlank(this.get(property))) {
              continue;
            }
            this['_validate' + validationName](property, validations[property]);
          }
        }
      }

      // Check if it's valid or not
      if (!this.get('isValidNow')) {
        // It may be invalid because of its relations
        if (Object.keys(errors).length !== 0) {
          var stateToTransition = this.get('isNew') ? 'created.uncommitted' : 'updated.uncommitted';
          this.transitionTo(stateToTransition);
          var recordModel = this.adapterDidInvalidate ? this : this._internalModel;
          store.recordWasInvalid(recordModel, errors);
        }
        return false;
      } else {
        return true;
      }
    },

    /**** Validators ****/
    _validateCustom: function _validateCustom(property, validation) {
      validation = _ember['default'].isArray(validation.custom) ? validation.custom : [validation.custom];
      for (var i = 0; i < validation.length; i++) {
        var customValidator = this._getCustomValidator(validation[i]);
        if (customValidator) {
          var passedCustomValidation = customValidator(property, this.get(property), this);
          if (!passedCustomValidation) {
            this.set('isValidNow', false);
            this._addToErrors(property, validation[i], _emberModelValidatorMessagesEn['default'].customValidationMessage);
          }
        }
      }
    },
    _validatePresence: function _validatePresence(property, validation) {
      var propertyValue = this.get(property);
      // If the property is an async relationship.
      if (this._modelRelations() && !_ember['default'].isBlank(this._modelRelations()[property])) {
        if (this._modelRelations()[property]['isAsync']) {
          propertyValue = this.get(property + '.content');
        }
      }
      if (_ember['default'].isBlank(propertyValue)) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.presence, _emberModelValidatorMessagesEn['default'].presenceMessage);
      }
    },
    _validateAbsence: function _validateAbsence(property, validation) {
      if (_ember['default'].isPresent(this.get(property))) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.absence, _emberModelValidatorMessagesEn['default'].absenceMessage);
      }
    },
    _validateAcceptance: function _validateAcceptance(property, validation) {
      var propertyValue = this.get(property),
          accept = validation.acceptance.accept || [1, '1', true];
      if (!_ember['default'].A(accept).contains(propertyValue)) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.acceptance, _emberModelValidatorMessagesEn['default'].acceptanceMessage);
      }
    },
    _validateFormat: function _validateFormat(property, validation) {
      var withRegexp = validation.format['with'];
      if (!this.get(property) || String(this.get(property)).match(withRegexp) === null) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.format, _emberModelValidatorMessagesEn['default'].formatMessage);
      }
    },
    _validateEmail: function _validateEmail(property, validation) {
      if (!this.get(property) || String(this.get(property)).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) === null) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.email, _emberModelValidatorMessagesEn['default'].mailMessage);
      }
    },
    _validateZipCode: function _validateZipCode(property, validation) {
      var DEFAULT_COUNTRY_CODE = 'US';
      var propertyValue = this.get(property);

      var countryCode = DEFAULT_COUNTRY_CODE;
      if (validation.zipCode.hasOwnProperty('countryCode')) {
        countryCode = validation.zipCode.countryCode;
      }
      if (_ember['default'].isArray(countryCode)) {
        countryCode.forEach(function (code) {
          var postalCodeRegexp = _emberModelValidatorPostalCodesRegex['default'][code];
          if (typeof postalCodeRegexp === 'undefined') {
            postalCodeRegexp = _emberModelValidatorPostalCodesRegex['default'][DEFAULT_COUNTRY_CODE];
          }
          if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
            this.set('isValidNow', false);
            this._addToErrors(property, validation.zipCode, _emberModelValidatorMessagesEn['default'].zipCodeMessage);
          }
        });
      } else {
        var postalCodeRegexp = _emberModelValidatorPostalCodesRegex['default'][countryCode];
        if (typeof postalCodeRegexp === 'undefined') {
          postalCodeRegexp = _emberModelValidatorPostalCodesRegex['default'][DEFAULT_COUNTRY_CODE];
        }
        if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.zipCode, _emberModelValidatorMessagesEn['default'].zipCodeMessage);
        }
      }
    },
    _validateColor: function _validateColor(property, validation) {
      var propertyValue = this.get(property);
      if (!propertyValue || String(propertyValue).match(/([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i) === null) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.color, _emberModelValidatorMessagesEn['default'].colorMessage);
      }
    },
    _validateURL: function _validateURL(property, validation) {
      var propertyValue = this.get(property);
      if (!propertyValue || String(propertyValue).match(/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/) === null) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.URL, _emberModelValidatorMessagesEn['default'].URLMessage);
      }
    },
    _validateSubdomain: function _validateSubdomain(property, validation) {
      var propertyValue = this.get(property),
          reserved = validation.subdomain.reserved || [];
      if (!propertyValue || String(propertyValue).match(/^[a-z\d]+([-_][a-z\d]+)*$/i) === null || reserved.indexOf(propertyValue) !== -1) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.subdomain, _emberModelValidatorMessagesEn['default'].subdomainMessage);
      }
    },
    _validateDate: function _validateDate(property, validation) {
      var propertyValue = new Date(this.get(property));
      if (isNaN(propertyValue.getTime())) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.date, _emberModelValidatorMessagesEn['default'].dateMessage);
        return;
      }
      if (validation.date.hasOwnProperty('before') && validation.date.before) {
        if (propertyValue.getTime() >= new Date(validation.date.before).getTime()) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.date, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].dateBeforeMessage, new Date(validation.date.before)));
        }
      }
      if (validation.date.hasOwnProperty('after') && validation.date.after) {
        if (propertyValue.getTime() <= new Date(validation.date.after).getTime()) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.date, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].dateAfterMessage, new Date(validation.date.after)));
        }
      }
    },
    _validateNumericality: function _validateNumericality(property, validation) {
      var propertyValue = this.get(property);
      if (!this._isNumber(this.get(property))) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.numericality, _emberModelValidatorMessagesEn['default'].numericalityMessage);
      }
      if (validation.numericality.hasOwnProperty('onlyInteger') && validation.numericality.onlyInteger) {
        if (!/^[+\-]?\d+$/.test(propertyValue)) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _emberModelValidatorMessagesEn['default'].numericalityOnlyIntegerMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('even') && validation.numericality.even) {
        if (propertyValue % 2 !== 0) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _emberModelValidatorMessagesEn['default'].numericalityEvenMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('odd') && validation.numericality.odd) {
        if (propertyValue % 2 === 0) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _emberModelValidatorMessagesEn['default'].numericalityOddMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('greaterThan') && this._isNumber(validation.numericality.greaterThan)) {
        if (propertyValue <= validation.numericality.greaterThan) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].numericalityGreaterThanMessage, validation.numericality.greaterThan));
        }
      }
      if (validation.numericality.hasOwnProperty('greaterThanOrEqualTo') && this._isNumber(validation.numericality.greaterThanOrEqualTo)) {
        if (propertyValue < validation.numericality.greaterThanOrEqualTo) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].numericalityGreaterThanOrEqualToMessage, validation.numericality.greaterThanOrEqualTo));
        }
      }
      if (validation.numericality.hasOwnProperty('equalTo') && this._isNumber(validation.numericality.equalTo)) {
        if (propertyValue !== validation.numericality.equalTo) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].numericalityEqualToMessage, validation.numericality.equalTo));
        }
      }
      if (validation.numericality.hasOwnProperty('lessThan') && this._isNumber(validation.numericality.lessThan)) {
        if (propertyValue >= validation.numericality.lessThan) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].numericalityLessThanMessage, validation.numericality.lessThan));
        }
      }
      if (validation.numericality.hasOwnProperty('lessThanOrEqualTo') && this._isNumber(validation.numericality.lessThanOrEqualTo)) {
        if (propertyValue > validation.numericality.lessThanOrEqualTo) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.numericality, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].numericalityLessThanOrEqualToMessage, validation.numericality.lessThanOrEqualTo));
        }
      }
    },
    _validateExclusion: function _validateExclusion(property, validation) {
      if (validation.exclusion.hasOwnProperty('in')) {
        if (validation.exclusion['in'].indexOf(this.get(property)) !== -1) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.exclusion, _emberModelValidatorMessagesEn['default'].exclusionMessage);
        }
      }
    },
    _validateInclusion: function _validateInclusion(property, validation) {
      if (validation.inclusion.hasOwnProperty('in')) {
        if (validation.inclusion['in'].indexOf(this.get(property)) === -1) {
          this.set('isValidNow', false);
          this._addToErrors(property, validation.inclusion, _emberModelValidatorMessagesEn['default'].inclusionMessage);
        }
      }
    },
    _validateMatch: function _validateMatch(property, validation) {
      var matching = validation.match.attr || validation.match,
          propertyValue = this.get(property),
          matchingValue = this.get(matching);
      if (propertyValue !== matchingValue) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.match, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].matchMessage, this._unCamelCase(matching)));
      }
    },
    // Length Validator
    _validateLength: function _validateLength(property, validation) {
      var propertyValue = this.get(property),
          stringLength = !propertyValue ? 0 : String(propertyValue).length,
          validationType = _ember['default'].typeOf(validation.length);
      if (validationType === 'number') {
        validation.length = { is: validation.length };
        this._exactLength(stringLength, property, validation);
      } else if (validationType === 'array') {
        validation.length = { minimum: validation.length[0], maximum: validation.length[1] };
        this._rangeLength(stringLength, property, validation);
      } else if (validationType === 'object') {
        if (validation.length.hasOwnProperty('is')) {
          this._exactLength(stringLength, property, validation);
        } else {
          this._rangeLength(stringLength, property, validation);
        }
      }
    },
    _exactLength: function _exactLength(stringLength, property, validation) {
      if (stringLength !== validation.length.is) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.length, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].wrongLengthMessage, validation.length.is));
      }
    },
    _rangeLength: function _rangeLength(stringLength, property, validation) {
      var minimum = -1,
          maximum = Infinity;
      // Maximum and Minimum can be objects
      if (_ember['default'].typeOf(validation.length.minimum) === 'number') {
        minimum = validation.length.minimum;
      } else if (_ember['default'].typeOf(validation.length.minimum) === 'object' && validation.length.minimum.hasOwnProperty('value')) {
        minimum = validation.length.minimum.value;
      }
      if (_ember['default'].typeOf(validation.length.maximum) === 'number') {
        maximum = validation.length.maximum;
      } else if (_ember['default'].typeOf(validation.length.maximum) === 'object' && validation.length.maximum.hasOwnProperty('value')) {
        maximum = validation.length.maximum.value;
      }

      if (stringLength < minimum) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.length.minimum, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].tooShortMessage, minimum));
      } else if (stringLength > maximum) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation.length.maximum, _ember['default'].String.fmt(_emberModelValidatorMessagesEn['default'].tooLongMessage, maximum));
      }
    },
    _validateRelations: function _validateRelations(property, validation) {
      var _this = this;

      if (validation.relations.indexOf("hasMany") !== -1) {
        if (this.get(property + '.content')) {
          this.get(property + '.content').forEach(function (objRelation) {
            if (!objRelation.validate()) {
              _this.set('isValidNow', false);
            }
          });
        }
      } else if (validation.relations.indexOf("belongsTo") !== -1) {
        if (this.get(property + '.content') && !this.get(property + '.content').validate()) {
          this.set('isValidNow', false);
        }
      }
    },
    _validateMustContainCapital: function _validateMustContainCapital(property, validation) {
      var notContainCapital = String(this.get(property)).match(/(?=.*[A-Z])/) === null,
          message = validation.mustContainCapital.message || _emberModelValidatorMessagesEn['default'].mustContainCapitalMessage;
      if (validation.mustContainCapital && notContainCapital) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainLower: function _validateMustContainLower(property, validation) {
      var containsLower = String(this.get(property)).match(/(?=.*[a-z])/) !== null,
          message = validation.mustContainLower.message || _emberModelValidatorMessagesEn['default'].mustContainLowerMessage;
      if (validation.mustContainLower && !containsLower) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainNumber: function _validateMustContainNumber(property, validation) {
      var containsNumber = String(this.get(property)).match(/(?=.*[0-9])/) !== null,
          message = validation.mustContainNumber.message || _emberModelValidatorMessagesEn['default'].mustContainNumberMessage;
      if (validation.mustContainNumber && !containsNumber) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainSpecial: function _validateMustContainSpecial(property, validation) {
      var regexString = validation.mustContainSpecial.acceptableChars || '-+_!@#$%^&*.,?()',
          regex = new RegExp('(?=.*[' + regexString + '])'),
          containsSpecial = String(this.get(property)).match(regex) !== null,
          message = validation.mustContainSpecial.message || _emberModelValidatorMessagesEn['default'].mustContainSpecialMessage;
      if (validation.mustContainSpecial && !containsSpecial) {
        this.set('isValidNow', false);
        this._addToErrors(property, validation, _ember['default'].String.fmt(message, regexString));
      }
    },

    /**** Helper methods ****/
    _exceptOrOnly: function _exceptOrOnly(property, options) {
      var validateThis = true;
      if (options.hasOwnProperty('except') && options.except.indexOf(property) !== -1) {
        validateThis = false;
      }
      if (options.hasOwnProperty('only') && options.only.indexOf(property) === -1) {
        validateThis = false;
      }
      return validateThis;
    },
    _getCustomValidator: function _getCustomValidator(validation) {
      var customValidator = validation;
      if (_ember['default'].typeOf(validation) === 'object' && validation.hasOwnProperty('validation')) {
        customValidator = validation.validation;
      }
      return this._isFunction(customValidator) ? customValidator : false;
    },
    _getCustomMessage: function _getCustomMessage(validationObj, defaultMessage, property) {
      if (_ember['default'].typeOf(validationObj) === 'object' && validationObj.hasOwnProperty('message')) {
        if (this._isFunction(validationObj.message)) {
          var msg = validationObj.message.call(property, this.get(property), this);
          return this._isString(msg) ? msg : defaultMessage;
        } else {
          return validationObj.message;
        }
      } else {
        return defaultMessage;
      }
    },
    _addToErrors: function _addToErrors(property, validation, defaultMessage) {
      var errors = this.get('validationErrors'),
          message = this._getCustomMessage(validation, defaultMessage, property),
          errorAs = _ember['default'].typeOf(validation) === 'object' ? validation.errorAs || property : property;
      if (!_ember['default'].isArray(errors[errorAs])) {
        errors[errorAs] = [];
      }
      if (this.get('addErrors')) {
        errors[errorAs].push([message]);
      }
    },
    _isNumber: function _isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    _unCamelCase: function _unCamelCase(str) {
      return str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return _ember['default'].String.capitalize(str);
      });
    },
    _isFunction: function _isFunction(func) {
      return _ember['default'].isEqual(_ember['default'].typeOf(func), 'function');
    },
    _isString: function _isString(str) {
      return _ember['default'].isEqual(_ember['default'].typeOf(str), 'string');
    },
    _modelRelations: function _modelRelations() {
      if (this.get('_relationships')) {
        return this.get('_relationships');
      } else {
        return this.get('_internalModel._relationships.initializedRelationships');
      }
    }
  });
});