define('ember-model-validator/messages/en', ['exports'], function (exports) {
  'use strict';

  exports['default'] = {
    presenceMessage: 'can\'t be blank',
    absenceMessage: 'must be blank',
    inclusionMessage: 'is not included in the list',
    exclusionMessage: 'is reserved',

    // Numericality
    numericalityMessage: 'is not a number',
    numericalityOnlyIntegerMessage: 'must be an integer',
    numericalityGreaterThanMessage: 'must be greater than %@',
    numericalityGreaterThanOrEqualToMessage: 'must be greater than or equal to %@',
    numericalityEqualToMessage: 'must be equal to %@',
    numericalityLessThanMessage: 'must be less than %@',
    numericalityLessThanOrEqualToMessage: 'must be less than or equal to %@',
    numericalityOddMessage: 'must be odd',
    numericalityEvenMessage: 'must be even',

    mailMessage: 'is not a valid email',
    formatMessage: 'is invalid',
    colorMessage: 'must be a valid CSS hex color code',
    subdomainMessage: 'must be a valid subdomain',
    acceptanceMessage: 'must be accepted',
    zipCodeMessage: 'is not a valid zip code',
    URLMessage: 'must be a valid url',
    wrongLengthMessage: 'is the wrong length (should be %@ characters)',
    tooShortMessage: 'is too short (minimum is %@ characters)',
    tooLongMessage: 'is too long (maximum is %@ characters)',
    mustContainNumberMessage: 'must include a number',
    mustContainSpecialMessage: 'must include one of these special characters: %@',
    mustContainLowerMessage: 'must include a lower case character',
    mustContainCapitalMessage: 'must include an upper case character',
    customValidationMessage: 'is invalid',
    matchMessage: 'must match %@',

    dateMessage: 'is not a valid date',
    dateBeforeMessage: 'must be before %@',
    dateAfterMessage: 'must be after %@'
  };
});