define("ember-model-validator/messages/fr", ["exports"], function (exports) {
  "use strict";

  exports["default"] = {
    presenceMessage: "doit être rempli(e)",
    absenceMessage: 'doit être vide',
    inclusionMessage: "n'est pas inclus(e) dans la liste",
    exclusionMessage: "n'est pas disponible",
    // Numericality
    numericalityMessage: "n'est pas un nombre",
    numericalityOnlyIntegerMessage: 'doit être un nombre entier',
    numericalityGreaterThanMessage: 'doit être supérieur à %@',
    numericalityGreaterThanOrEqualToMessage: 'doit être supérieur ou égal à %@',
    numericalityEqualToMessage: 'doit être égal à %@',
    numericalityLessThanMessage: 'doit être inférieur à %@',
    numericalityLessThanOrEqualToMessage: 'doit être inférieur ou égal à %@',
    numericalityOddMessage: 'doit être impair',
    numericalityEvenMessage: 'doit être pair',

    mailMessage: "n'est pas une adresse email valide",
    formatMessage: "n'est pas valide",
    colorMessage: 'doit être un nombre hexadécimal valide',
    subdomainMessage: 'doit être un sous-domaine valide',
    acceptanceMessage: 'doit être accepté(e)',
    zipCodeMessage: "n'est pas un code postal valide",
    URLMessage: 'doit être une URL valide',
    wrongLengthMessage: "ne fait pas la bonne longueur (doit comporter %@ caractères)",
    tooShortMessage: "est trop court (au moins %@ caractères)",
    tooLongMessage: "est trop long (pas plus de %@ caractères)",
    mustContainNumberMessage: 'doit inclure un nombre',
    mustContainSpecialMessage: "doit inclure l'un de ces caractères spéciaux: %@",
    mustContainLowerMessage: 'doit comporter un caractère minuscule',
    mustContainCapitalMessage: 'doit comporter un caractère majuscule',
    customValidationMessage: "n'est pas valide",
    matchMessage: 'doit correspondre avec %@'
  };
});